export const list  = [
    {
      title: 'Stake your WERAM Staking',
      subtitle:'Reward WERAM Staking.',
      icon:{
        stakeCoin:'assets/images/icon/icon_coinbase.png',
        rewardCoin:'assets/images/icon/icon_coinbase.png'
      },
      apy:{
        label:'APY',
        value:'15%',
      },
      totalStake:{
        label:'Total Stake',
        value:'100.000 WERAM'
      },
      totalStaker:{
        label:'Total Stakers',
        value:'410'
      },
      contract:'0x1AF8E8FDacD05a7922E626A2C1545dbb90fa1CD9',
      link: '/staking/0x1AF8E8FDacD05a7922E626A2C1545dbb90fa1CD9',
      status:'participate'
    },
    // {
    //     title: 'Stake your $USDT',
    //     subtitle:'Reward $BUSD.',
    //     icon:{
    //         stakeCoin:'assets/images/icon/icon_usdt.png',
    //         rewardCoin:'assets/images/icon/icon_bnb.png'
    //     },
    //     apy:{
    //       label:'APY',
    //       value:'14%',
    //     },
    //     totalStake:{
    //       label:'Total Stake',
    //       value:'100.000 USDT'
    //     },
    //     totalStaker:{
    //       label:'Total Staker',
    //       value:'230'
    //     },
    //     contract:'-',
    //     link: '/soon',
    //     status:'upcoming'
    //   },
    //   {
    //     title: 'Stake your $SHIB',
    //     subtitle:'Reward $BUSD.',
    //     icon:{
    //         stakeCoin:'assets/images/icon/icon_shib.png',
    //         rewardCoin:'assets/images/icon/icon_bnb.png'
    //     },
    //     apy:{
    //       label:'APY',
    //       value:'10%',
    //     },
    //     totalStake:{
    //       label:'Total Stake',
    //       value:'100.000 SHIB'
    //     },
    //     totalStaker:{
    //         label:'Total Staker',
    //         value:'320'
    //     },
    //     contract:'-',
    //     link: '/soon',
    //     status:'upcoming'
    //   },
  ];
  