
import AbiToken from "../abi/AbiToken.json";
import AbiStakingPool from "../abi/AbiStakingPool.json";
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};

//TESTNET
export const RPC_NODE_TESTNET={
    http:"https://mainnet-rpc.eramscan.com/",
    wss:"wss://mainnet-rpc.eramscan.com/ws",
}

export const RPC_NODE={
    http:"https://mainnet-rpc.eramscan.com",
    wss:"wss://mainnet-rpc.eramscan.com/ws",
}

export const TOKEN_STAKE ={
    address:'0x1336F973cBd16DB4368655514395D0818a949c2c',
    abi: AbiToken
}

export const POOL_STAKE ={
    address:'0x1AF8E8FDacD05a7922E626A2C1545dbb90fa1CD9',
    abi: AbiStakingPool
}