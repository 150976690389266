import React, { useState } from "react";
import StakePools from "../components/List/StakePools";
import { list } from "../data/StakingPoolLists";

function Home() {
  const [poolList, setPoolList] = useState(list);
  const discoverStyle = {
    background:"#08B0FF",
    color:"#FFFFFF",
    borderRadius:'30px !important'
  };

  return (
    <>
      <div className="container container-mobile  px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-lg-6">
            <div className="head-bar" />
            <h1 className="display-5 lh-1 my-3 title-text fw-600">
              Just Stake ERAM To Earn 
            </h1>
            <p className="lead py-3" style={{color:'#676767'}}>
            ERAM Coin's stake in an advanced Delegated Proof of Stake (DPoS) blockchain enables users to engage in profit-sharing from the larger ERAM Smart Chain project.
            </p>
            <p className="lead py-3" style={{color:'#676767'}}>
              ERAM, a pioneering NeoBanking Blockchain, Powered by the Central Bank, boasts a staggering Billions Trading Volume (24h) and holds over Billions in Financial Reserves (Total Assets).
            </p>
           
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-lg px-4 me-md-2 rounded rounded-pill"
                style={discoverStyle}
              >
                Discover
              </button>
            </div>
          </div>
          <div className="col-sm-8 col-lg-6">
            <img
              src="assets/images/misc/hero.png"
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="530"
              height="543"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <StakePools poolList={poolList} />
      </div>
    </>
  );
}

export default Home;
