import React from "react";
class Footer extends React.Component {
  render() {
    return(
      <div className ="footer-margin">
        <div className="container">
          <footer className="row justify-content-between align-items-center py-3">
            <div className="col-md-6 d-flex flex-col align-items-center flex-wrap">
              <div className="flex-wrap">
                <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logo-text1.png'} width="auto" height="32"/>
                </a>
                <span className="mb-3 mb-md-0" style={{color:'#313743',fontWeight: '700',fontSize: '18px',paddingTop: '15px',paddingLeft: '15px'}}>
                  {/* &copy; 2023 eramscan.com, Inc */}
                    Power by ERAM Smart Chain
                  </span>
              </div>
              <div style={{color:'#313743',width:'100%',marginTop:'30px',marginBottom:'30px',fontSize:'14px'}}>
                EramStake involves holding and voting to earn rewards, operating under the supervision of the central bank, and ensuring the security of the network's decentralized consensus.
              </div>
              <div className="flex flex-wrap flex-wrap-footer" style={{color:'#313743',marginBottom:'30px',fontSize:'14px',gap: '20px',display: 'flex'}}>
                {/* <a href="#" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/neobank_icon.svg'}  style={{marginRight:'10px'}}/>
                  NeoBank(ZedPay)
                </a>
                <a href="#" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/zedcex_icon.svg'}  style={{marginRight:'10px'}}/>
                  CEX1(ERAM)
                </a>
                <a href="#" target="_blank" className="flex flex-wrap CEX2" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/zedcex_icon.svg'}  style={{marginRight:'10px'}}/>
                  CEX2(ZedCex)
                </a> */}
                <a href="https://eramscan.com" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedScan.png'} width="17" height="18" style={{marginRight:'10px'}}/>
                  EramScan
                </a>
                <a href="#" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedDex.png'} width="21" height="17" style={{marginRight:'10px'}}/>
                  EramDex
                </a>
                <a href="#" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedStake.png'} width="17" height="17" style={{marginRight:'10px'}}/>
                  EramBridge
                </a>
                {/* <a href="#" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedToken.png'} width="21" height="15" style={{marginRight:'10px'}}/>
                  EramToken
                </a>
                <a href="#" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedDrop.png'} width="21" height="15" style={{marginRight:'10px'}}/>
                  EramDrop
                </a> */}
              </div>
              <div className="flex flex-wrap" style={{color:'#313743',width: '100%'}}>
                <a href="https://www.facebook.com/zedxioncoin" target="_blank">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra.png'} />
                </a>
                <a href="https://x.com/zedxionc" target="_blank" className="ms-3">
                 <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra1.png'} />
                </a>
                <a href="https://www.instagram.com/zedxion/" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra2.png'} />
                </a>
                <a href="https://www.youtube.com/channel/UCVbVWWdHHrpRkRL80_CoDZg" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra3.png'} />
                </a>
                <a href="https://discord.com/invite/UDDb7FZF" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra4.png'} />
                </a>
                <a href="https://medium.com/subscribe/@zedxion_exchange" target="_blank" style={{ marginTop: '4px' }} className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra5.png'} />
                </a>
                <a href="https://www.linkedin.com/company/zedxion-exchnage-crypto" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra6.png'} />
                </a>
                <a href="https://github.com/Zedxioncryptocurrency" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra7.png'} />
                </a>
              </div>
              <div className="flex flex-wrap" style={{color:'#313743',width: '100%',marginTop:'30px',fontSize:'13px'}}>
                ERAM Chain © 2024      |    
                <a href="https://docs.eramscan.com/eram-whitepaper/" target="_blank" className="ml-[5px]" style={{color:'#08B0FF',marginLeft:'5px'}}>
                  WhitePaper
                </a>
              </div>
            </div>

            {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3"><a className="text-success" style={{color:'#000000'}} href="#"><i className="fa fa-twitter"></i></a></li>
              <li className="ms-3"><a className="text-success" style={{color:'#000000'}} href="#"><i className="fa fa-instagram"></i></a></li>
              <li className="ms-3"><a className="text-success" style={{color:'#000000'}} href="#"><i className="fa fa-telegram"></i></a></li>
            </ul> */}
            <div className="col-md-6 row" style={{marginTop:'65px'}}>
              <div className="col-md-4 menu-ul-footer">
                <a href="#" style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                  Company
                </a>
                <a href="#" style={{color:'#313743',fontWeight:'500'}} className="text-[13px]">
                  Delegate to EramScan
                </a>
                <a href="#" style={{color:'#313743', marginBottom: '3px'}} className="text-[13px]">
                  <p style={{background: '#08B0FF',borderRadius:'13px',padding:'4px 8px',width: '70px', fontSize:'13px', textAlign:'center', color:'#fff'}}>Staking</p>
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Brand Assets
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Contact Us
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Terms & Privacy
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Bug Bounty
                </a>
              </div>
              <div className="col-md-4 menu-ul-footer">
                <a href="#" style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                  Community
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  API Documentation
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Knowledge Base
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Network Status
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Learn Eram
                </a>
                <a href="#">
                
                </a>
              </div>
              <div className="col-md-4 menu-ul-footer">
                <a href="#" style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                  Products & Services
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Advertise
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Explorer-as-a-Service (EaaS)
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  API Plans
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Priority Support
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Blockscan
                </a>
              </div>
            </div>
          </footer>
        </div>
      
      </div>
    );
  }
}
export default Footer;